import { createStyles } from '@mantine/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import Link from 'next/link';
import { t, Trans } from '@lingui/macro';
import {
  Button,
  TextInput,
  PasswordInput,
  Text,
  UnstyledButton,
} from '@akin/ui-lib';
import getError from '../lib/formik';
import { login } from '../services/auth';
import { getAuthLayout as AuthLayout } from '../components/layouts';
import useAuth from '../hooks/useAuth';
import PAGE_ROUTES from '../constants/pageRoutes';
import { showError } from '../lib/notifications';
import PageTitle from '../components/layouts/PageTitle';
import { acceptInvite } from '../services/invite';
import useURLParams from '../hooks/useURLParams';
import useSpaceSettings from '../hooks/useSpaceSettings';
import AuthPageWrapper from '../components/auth/AuthPageWrapper';
import InputLabel from '../components/auth/InputLabel';
import { getLandingPageRoute } from '../features/permissions/utils';

const useStyles = createStyles(() => ({
  cardContainer: {
    maxWidth: 400,
    margin: '0 auto',
  },
  linkButton: {
    marginLeft: 5,
    fontSize: 12,
    color: '#9984D4',
    fontWeight: 500,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      fontSize: 'inherit',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(<Trans>Email is a required field</Trans>),
  password: Yup.string().required(<Trans>Password is a required field</Trans>),
});

export default function Login() {
  const { classes } = useStyles();
  const auth = useAuth();
  const { router, getURLparams, routeToReturnURL } = useURLParams();
  const query = getURLparams();
  const { switchToSpace } = useSpaceSettings();

  const [isLoading, setIsLoading] = useState(false);

  const redirectToSignup = () => {
    let queryParams = {};

    // Set query params for invite space flow
    if (query?.inviteToken) {
      queryParams = {
        inviteToken: query?.inviteToken,
        inviteeEmail: query?.inviteeEmail,
      };
    }

    // Set query params for invite collaboration flow
    if (query?.collaborationInvite) {
      queryParams = {
        collaborationInvite: true,
        return_url: query.return_url,
        inviteeEmail: query?.inviteeEmail,
      };
    }

    router.push({
      pathname: PAGE_ROUTES.signup,
      query: { ...queryParams },
    });
  };

  const formik = useFormik({
    initialValues: {
      email: query?.inviteeEmail || '',
      password: '',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (isLoading) return;

      setIsLoading(true);

      const data = {
        email: values.email,
        password: values.password,
      };

      try {
        let selectedSpace = null;

        const { data: loginData } = await login(data);
        let userData = { ...loginData };

        if (loginData?.spaces?.length > 0) {
          // Find brand space in spaces list and set it
          // as default selected space
          selectedSpace = loginData.spaces.find(
            (space) => space?.ai_subtenant_code === '*'
          );

          // If brand space is not found, set the first space
          // in spaces list as default selected space.
          if (!selectedSpace) {
            selectedSpace = { ...loginData.spaces[0] };
          }
        }

        // If inviteToken param is present
        // accept space invite and login to invited space
        if (query?.inviteToken) {
          try {
            const inviteResponse = await acceptInvite({
              token: query.inviteToken,
            });

            userData = {
              ...loginData,
              spaces: [...loginData.spaces, inviteResponse.space],
              permissions: inviteResponse?.permissions && {
                ...inviteResponse.permissions,
              },
            };

            // Set the invited space as selected space.
            selectedSpace = inviteResponse.space;
          } catch (error) {
            auth.logout();
            showError(error.message);
            setIsLoading(false);
            return;
          }
        }

        auth.login(userData);

        // Redirect to collaboration-invite page if collaborationInvite flow after login
        if (query?.collaborationInvite) {
          routeToReturnURL();
          return;
        }

        if (!selectedSpace) {
          throw new Error('Default space is being not set');
        }

        await switchToSpace(selectedSpace.space_id);

        const landingPageRoute = getLandingPageRoute({
          currentSpaceId: selectedSpace.space_id,
          userPermissions: userData?.permissions && { ...userData.permissions },
        });

        router.replace({
          pathname: landingPageRoute,
        });
      } catch (error) {
        showError(error.message);
      }

      setIsLoading(false);
    },
  });

  return (
    <AuthPageWrapper>
      <PageTitle pageName={t`Login`} />
      <div>
        <div className={classes.cardContainer}>
          <Text mb="lg" size={30} weight={500}>
            <Trans>Login to D3x</Trans>
          </Text>
          <form onSubmit={formik.handleSubmit}>
            <TextInput
              type="email"
              id="email"
              name="email"
              label={<InputLabel title={t`Email`} />}
              placeholder="name@email.com"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={getError('email', formik).msg}
              disabled={!!query?.inviteeEmail}
              required
              mb="sm"
            />
            <PasswordInput
              id="password"
              name="password"
              label={<InputLabel title={t`Password`} />}
              placeholder={t`password`}
              onChange={formik.handleChange}
              value={formik.values.password}
              error={getError('password', formik).msg}
              required
              mb="sm"
            />
            <Text align="right" mb="sm" className={classes.linkButton}>
              <Link href={PAGE_ROUTES.passwordReset}>{t`Forgot Password`}</Link>
            </Text>
            <Button type="submit" loading={isLoading} fullWidth>
              <Trans>Login</Trans>
            </Button>
          </form>

          <Text align="center" size={12} color="dimmed" mt="lg">
            <Trans>{`Don't have an account?`}</Trans>

            <UnstyledButton
              className={classes.linkButton}
              onClick={redirectToSignup}
            >
              <Trans>Signup</Trans>
            </UnstyledButton>
          </Text>
        </div>
      </div>
    </AuthPageWrapper>
  );
}

Login.getLayout = AuthLayout;
