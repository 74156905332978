import http from './axiosConfig';
import APIError from './customErrors';

export const createInvite = async (data) => {
  try {
    const response = await http.post(`/team/invite`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const getTeamInfo = async () => {
  try {
    const response = await http.get(`/team`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const acceptInvite = async (data) => {
  try {
    const response = await http.post(`/team/invite/accept`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const deleteInvite = async (inviteId) => {
  try {
    const response = await http.delete(`/team/invite/${inviteId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const removeSpaceMember = async (userId) => {
  try {
    const response = await http.delete(`/team/${userId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};
